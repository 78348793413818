import { get, post } from './request'
// 公用字典接口
export const getDict = (type) => get(`/admin/dict/type/${type}`)
// 获取userid对应的客户信息
export const getCustomerInfo = (userId, params, headers = {}) =>
  get(`/customer/rel/cust/customer/getCustomerInfo/${userId}`, params, headers)
// 获取所有地址
export const getAddressList = (params) =>
  get(
    `/customer/attrmanager/getCustomerAddress/${params.custCode}/${params.yhno}`
  )

export const getRegion = () => get('/admin/region/getSysRegionAllTree')

// 获取优惠券列表
export const getCoupon = (data) =>
  post(`/dp/h5/DpSocPromotion/goods/match/couponList`, data)

export const getPaymentStandard = (data) =>
  post(`/customer/h5/rel/customer/getPaymentStandard`, data)

export const getBcompanyPermission = (data) =>
  get(`/customer/h5/rel/customer/bcompany/permission/${data.custCode}`, data)

export const getCloudPermission = (data) =>
  get(`/customer/h5/rel/customer/cloud/permission/${data.custCode}`, data)

export const getBoomPermission = (data) =>
  get(`/customer/h5/rel/customer/boom/permission/${data.custCode}`, data)

export const getBcompanyPermissionSubject = (data) =>
  get(
    `/customer/h5/rel/customer/bcompany/permission/${data.custCode}/${data.subjectCode}`,
    data
  )
