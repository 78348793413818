import { render, staticRenderFns } from "./productCard.vue?vue&type=template&id=a4299938&scoped=true&"
import script from "./productCard.vue?vue&type=script&lang=js&"
export * from "./productCard.vue?vue&type=script&lang=js&"
import style0 from "./productCard.vue?vue&type=style&index=0&id=a4299938&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4299938",
  null
  
)

export default component.exports